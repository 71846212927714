// action - state management
import { format } from 'date-fns';
import {
  SET_SHIPMENT_LIST,
  CLEAR_SHIPMENT_LIST,
  SET_SHIPMENT_ROW,
  SET_SHIPMENT_DETAIL,
  SET_SHIPMENT_LIST_PAGE_KEYS,
  SET_SHIPMENT_LIST_LAST_PAGE,
  SET_SHIPMENT_LIST_PAGE_NUMBER,
  SET_SHIPMENT_LIST_PARAMS,
  SET_SHIPMENT_LIST_ROW_LENGTH,
  SET_SHIPMENT_LIST_START_AND_END_DATE,
  SET_SHIPMENT_LIST_WITH_VOIDED_RECORD
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //
let start = new Date();
start.setHours(0, 0, 0, 0);

let end = new Date();
end.setHours(23, 59, 59, 999);

const initialState = {
  shipmentList: [],
  shipmentRow: [],
  page_keys: [],
  lastPage: false,
  rowsPerPage: 100,
  page: 1,
  start_date: format(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  end_date: format(end, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  params: {
    page: 1,
    rowsPerPage: 50,
    search_id: null,
    pageKeys: [],
    status: [],
    shipmentType: '',
    warehouse_id: '',
    company_id: ''
  }
};

const shipmentsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPMENT_LIST: {
      return {
        ...state,
        shipmentList: [...state.shipmentList, ...action.payload]
      };
    }
    case SET_SHIPMENT_ROW: {
      return {
        ...state,
        shipmentRow: action.payload
      };
    }
    case CLEAR_SHIPMENT_LIST: {
      return {
        ...state,
        shipmentList: []
      };
    }
    case SET_SHIPMENT_DETAIL: {
      return {
        ...state,
        shipmentDetail: action.payload
      };
    }
    // This action is added for fixing duplicate list data issue
    // It is taking only one record after voiding row and then replace the particular object from shipmentlist
    case SET_SHIPMENT_LIST_WITH_VOIDED_RECORD: {
      const rows = state.shipmentList.map(u =>
        u.shipment_id !== action.payload.shipment_id ? u : action.payload
      );
      return { ...state, shipmentList: [...rows] };
    }
    case SET_SHIPMENT_LIST_ROW_LENGTH: {
      return {
        ...state,
        rowsPerPage: action.payload
      };
    }
    case SET_SHIPMENT_LIST_PAGE_NUMBER: {
      return {
        ...state,
        page: action.payload
      };
    }
    case SET_SHIPMENT_LIST_PAGE_KEYS: {
      return {
        ...state,
        page_keys: action.payload
      };
    }
    case SET_SHIPMENT_LIST_LAST_PAGE: {
      return {
        ...state,
        lastPage: action.payload
      };
    }
    case SET_SHIPMENT_LIST_PARAMS: {
      return {
        ...state,
        params: action.payload
      };
    }
    case SET_SHIPMENT_LIST_START_AND_END_DATE: {
      return {
        ...state,
        start_date: action.payload[0],
        end_date: action.payload[1]
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default shipmentsDataReducer;
