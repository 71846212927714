import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store, persister } from 'store';
// style + assets
import 'assets/scss/style.scss';
import config from 'config.js';
import Amplify from 'aws-amplify';

// ==============================|| REACT DOM RENDER  ||============================== //
Amplify.configure({
  API: {
    graphql_endpoint: config.graphql_endpoint
  },
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

console.log('Environment:-', process.env.REACT_APP_ENV);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
