import { Auth } from 'aws-amplify';
import axiosApi from '_mockApis/axiosApi.js';

export async function authenticate(username, password) {
  return await Auth.signIn(username, password);
}

// this function verifys the user e-mail and updates user attributes

/*
 * Fixme: Create user Action file and move this code to that file
 * */
export async function verifyEmail(email, verificationCode) {
  return axiosApi({
    method: 'get',
    url: `/users/verifyEmail?email=${email}&code=${verificationCode}`
  });
}

export async function logout(navigate) {
  localStorage.clear();
  await Auth.signOut();
  navigate('/login');
}

export const getSession = async () => {
  return await Auth.currentSession();
};
