import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './PrivateRoute';

// dashboard routingmultiple
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardManageUsers = Loadable(lazy(() => import('views/dashboard/ManageUsers')));
const AuthCompany = Loadable(lazy(() => import('views/pages/company')));
const Support = Loadable(lazy(() => import('views/pages/support')));
const ContactList = Loadable(lazy(() => import('views/pages/contact-list')));
const ShipmentHistory = Loadable(lazy(() => import('views/pages/shipmentHistory')));

const HelpCenter = Loadable(lazy(() => import('views/pages/help-center')));

const AuthWarehouseLevel = Loadable(lazy(() => import('views/pages/company/warehouseLevel')));
const ZoneLevelData = Loadable(lazy(() => import('views/pages/company/ZonelevelData')));

// application - customer routing
const AppCustomerList = Loadable(lazy(() => import('views/application/customer/customerList')));
const DashboardCompanies = Loadable(
  lazy(() => import('views/application/customer/customerList/Companies'))
);
const WarehouseIndex = Loadable(
  lazy(() => import('views/application/customer/customerList/Warehouse/index'))
);
const AppWarehouseList = Loadable(lazy(() => import('views/application/customer/wareHouseList')));
const AppCustomerShipmentList = Loadable(
  lazy(() => import('views/application/customer/ShipmentList'))
);
const AppCustomerNetwork = Loadable(lazy(() => import('views/application/customer/Network')));

const AppCustomerShipmentLabels = Loadable(
  lazy(() => import('views/application/customer/ShipmentLabels'))
);
// application - express table routing
const APPExpressTable = Loadable(lazy(() => import('views/application/customer/ExpressTable')));

// application - accessorial editor table routing
const APPAccessorialEditor = Loadable(
  lazy(() => import('views/application/customer/AccessorialEditor'))
);
// tracking routing
const AppTracking = Loadable(lazy(() => import('views/application/tracking')));

const AppCustomerShipment = Loadable(lazy(() => import('views/application/customer/Shipment')));
const AppCustomerShipmentDetails = Loadable(
  lazy(() => import('views/application/customer/ShipmentDetails'))
);
const AppCustomerCompanyDetails = Loadable(
  lazy(() => import('views/application/customer/customerList/customerDetails'))
);
const AppCustomerWarehouseDetails = Loadable(
  lazy(() => import('views/application/customer/wareHouseList/wareHouseDetails'))
);
const AuthReviewSubmission = Loadable(lazy(() => import('views/pages/company/review-submission')));
const AuthFinalReview = Loadable(lazy(() => import('views/pages/company/final-review')));

// ==============================|| MAIN ROUTING ||============================== //
const redirectTo = '/login';
const MainRoutes = allowedRoles => {
  return {
    path: '/',
    element: <MainLayout />,
    children: [
      // operation functionlity route
      {
        path: '/operations/sort',
        element: (
          <PrivateRoute
            path="/operations/sort"
            component={AppTracking}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      // shipment functionality route
      {
        path: '/customer/shipment-list',
        element: (
          <PrivateRoute
            path="/customer/shipment-list"
            component={AppCustomerShipmentList}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/network',
        element: (
          <PrivateRoute
            path="/customer/network"
            component={AppCustomerNetwork}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/shipment-labels/:id',
        element: (
          <PrivateRoute
            path="/customer/shipment-labels/:id"
            component={AppCustomerShipmentLabels}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/shipment/:id/:sKey',
        element: (
          <PrivateRoute
            path="/customer/shipment/:id/:sKey"
            component={AppCustomerShipment}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/:screen/shipment/:id/:sKey',
        element: (
          <PrivateRoute
            path="/customer/shipment/:id/:sKey"
            component={AppCustomerShipment}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/shipment',
        element: (
          <PrivateRoute
            path="/customer/shipment"
            component={AppCustomerShipment}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/:screen/shipment-details/:id/:sKey',
        element: (
          <PrivateRoute
            path="/customer/:screen/shipment-details/:id/:sKey"
            component={AppCustomerShipmentDetails}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      {
        path: '/customer/shipment-details/:id/:sKey',
        element: (
          <PrivateRoute
            path="/customer/shipment-details/:id/:sKey"
            component={AppCustomerShipmentDetails}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipment}
          />
        )
      },
      // admin functionality route
      {
        path: '/customer/company-list',
        element: (
          <PrivateRoute
            path="/customer/company-list"
            component={AppCustomerList}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company',
        element: (
          <PrivateRoute
            path="/customer/company"
            component={AuthCompany}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company/:sKey',
        element: (
          <PrivateRoute
            path="/customer/company/:sKey"
            component={AuthCompany}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/review',
        element: (
          <PrivateRoute
            path="/customer/review"
            component={AuthReviewSubmission}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/final-review',
        element: (
          <PrivateRoute
            path="/customer/final-review"
            component={AuthFinalReview}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-list/warehouse-level',
        element: (
          <PrivateRoute
            path="/customer/company-list/warehouse-level"
            component={AuthWarehouseLevel}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-list/:sKey/warehouse/:ware_id/warehouse-level',
        element: (
          <PrivateRoute
            path="/customer/company-list/:sKey/warehouse/:ware_id/warehouse-level"
            component={AuthWarehouseLevel}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-list/warehouse-level/zone-level',
        element: (
          <PrivateRoute
            path="/customer/company-list/warehouse-level/zone-level"
            component={ZoneLevelData}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-list/:sKey/warehouse/:ware_id/zone-level',
        element: (
          <PrivateRoute
            path="/customer/company-list/:sKey/warehouse/:ware_id/zone-level"
            component={ZoneLevelData}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-list/:companyId/warehouse/:warehouseId',
        element: (
          <PrivateRoute
            path="/customer/company-list/:companyId/warehouse/:warehouseId"
            component={WarehouseIndex}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/customer/company-list/:sKey/:tab',
        element: (
          <PrivateRoute
            path="/customer/company-list/:sKey/:tab"
            component={DashboardCompanies}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/customer/warehouse-list',
        element: (
          <PrivateRoute
            path="/customer/warehouse-list"
            component={AppWarehouseList}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/express_price',
        element: (
          <PrivateRoute
            path="/customer/express_price"
            component={APPExpressTable}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/accessorial',
        element: (
          <PrivateRoute
            path="/customer/accessorial"
            component={APPAccessorialEditor}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/operations/sort',
        element: (
          <PrivateRoute
            path="/operations/sort"
            component={AppTracking}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/customer/warehouse-details',
        element: (
          <PrivateRoute
            path="/customer/warehouse-details"
            component={AppCustomerWarehouseDetails}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      {
        path: '/customer/company-details',
        element: (
          <PrivateRoute
            path="/customer/company-details"
            component={AppCustomerCompanyDetails}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.admin}
          />
        )
      },
      // temporary Dashboard route
      {
        path: '/dashboard/default',
        element: (
          <PrivateRoute
            path="/dashboard/default"
            component={DashboardDefault}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.shipper}
          />
        )
      },
      {
        path: '/dashboard/userManager',
        element: (
          <PrivateRoute
            path="/dashboard/userManager"
            component={DashboardManageUsers}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/dashboard/contact-list',
        element: (
          <PrivateRoute
            path="/dashboard/contact-list"
            component={ContactList}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/shipment-history',
        element: (
          <PrivateRoute
            path="/shipment-history"
            component={ShipmentHistory}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/dashboard/helpcenter',
        element: (
          <PrivateRoute
            path="/dashboard/helpcenter"
            component={HelpCenter}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      },
      {
        path: '/dashboard/support',
        element: (
          <PrivateRoute
            path="/dashboard/support"
            component={Support}
            redirectTo={redirectTo}
            allowedRoles={allowedRoles.operations}
          />
        )
      }
    ]
  };
};

export default MainRoutes;
