// theme constant
export const gridSpacing = 3;
export const drawerWidth = 272;
export const appDrawerWidth = 320;
export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const phoneMask = rawValue => {
  const phoneNumber = rawValue.replace(/\D/g, '');
  const isEleventhDigit = phoneNumber.length > 11;

  if (isEleventhDigit) {
    return [
      '1',
      ' ',
      '(',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ')',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ' ',
      'e',
      'x',
      't',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ];
  } else {
    return [
      '1',
      ' ',
      '(',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      ')',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ];
  }
};
