// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconPackage,
  IconListCheck,
  IconUsers
} from '@tabler/icons';
import HubIcon from '@mui/icons-material/Hub';
import { HomeIcon, NoteText, LoadingTrolley, ListUnordered, ReportIcon } from 'assets/images/icons';
import { Typography } from '@mui/material';

// constant
const icons = {
  IconUsers,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconPackage,
  IconListCheck,
  HomeIcon,
  NoteText,
  LoadingTrolley,
  ListUnordered,
  HubIcon,
  ReportIcon
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //
const application = {
  id: 'application',
  title: <FormattedMessage id="dashboard" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="dashboard" />
        </Typography>
      ),
      type: 'item',
      url: '/dashboard/default',
      icon: icons.HomeIcon,
      breadcrumbs: false
    },
    {
      id: 'shipmentList',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="shipmentList" />
        </Typography>
      ),
      type: 'item',
      url: '/customer/shipment-list',
      icon: icons.ListUnordered,
      breadcrumbs: false
    },
    {
      id: 'shipmentHistory',
      title: (
        <Typography variant="subtitle-history">
          <FormattedMessage id="shipmentHistory" />
        </Typography>
      ),
      type: 'item',
      url: '/shipment-history',
      icon: icons.ReportIcon,
      breadcrumbs: false
    }
    // {
    //     id: 'users',
    //     title: <FormattedMessage id="users" />,
    //     type: 'collapse',
    //     icon: icons.IconUserCheck,
    //     children: [
    //         {
    //             id: 'posts',
    //             title: <FormattedMessage id="social-profile" />,
    //             type: 'item',
    //             url: '/user/social-profile/posts'
    //         },
    //         {
    //             id: 'account-profile',
    //             title: <FormattedMessage id="account-profile" />,
    //             type: 'collapse',
    //             children: [
    //                 {
    //                     id: 'profile1',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="profile" /> 01
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/account-profile/profile1'
    //                 },
    //                 {
    //                     id: 'profile2',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="profile" /> 02
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/account-profile/profile2'
    //                 },
    //                 {
    //                     id: 'profile3',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="profile" /> 03
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/account-profile/profile3'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'user-card',
    //             title: <FormattedMessage id="cards" />,
    //             type: 'collapse',
    //             children: [
    //                 {
    //                     id: 'card1',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="style" /> 01
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/card/card1'
    //                 },
    //                 {
    //                     id: 'card2',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="style" /> 02
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/card/card2'
    //                 },
    //                 {
    //                     id: 'card3',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="style" /> 03
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/card/card3'
    //                 }
    //             ]
    //         },
    //         {
    //             id: 'user-list',
    //             title: <FormattedMessage id="list" />,
    //             type: 'collapse',
    //             children: [
    //                 {
    //                     id: 'list1',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="style" /> 01
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/list/list1'
    //                 },
    //                 {
    //                     id: 'list2',
    //                     title: (
    //                         <>
    //                             <FormattedMessage id="style" /> 02
    //                         </>
    //                     ),
    //                     type: 'item',
    //                     url: '/user/list/list2'
    //                 }
    //             ]
    //         }
    //     ]
    // },
    // {
    //     id: 'customer',
    //     title: <FormattedMessage id="customer shipments" />,
    //     type: 'collapse',
    //     icon: icons.IconBasket,
    //     children: [
    //         {
    //             id: 'customer-list',
    //             title: <FormattedMessage id="company-list" />,
    //             type: 'item',
    //             url: '/customer/company-list',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'warehouse-list',
    //             title: <FormattedMessage id="warehouse-list" />,
    //             type: 'item',
    //             url: '/customer/warehouse-list',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'shipment-list',
    //             title: <FormattedMessage id="shipment-list" />,
    //             type: 'item',
    //             url: '/customer/shipment-list',
    //             breadcrumbs: false
    //         },
    //     ]
    // },
    // {
    //     id: 'chat',
    //     title: <FormattedMessage id="chat" />,
    //     type: 'item',
    //     icon: icons.IconMessages,
    //     url: '/app/chat'
    // },
    // {
    //     id: 'kanban',
    //     title: 'Kanban',
    //     type: 'item',
    //     icon: icons.IconLayoutKanban,
    //     url: '/app/kanban'
    // },
    // {
    //     id: 'mail',
    //     title: <FormattedMessage id="mail" />,
    //     type: 'item',
    //     icon: icons.IconMail,
    //     url: '/app/mail'
    // },
    // {
    //     id: 'calendar',
    //     title: <FormattedMessage id="calendar" />,
    //     type: 'item',
    //     url: '/app/calendar',
    //     icon: icons.IconCalendar,
    //     breadcrumbs: false
    // },
    // {
    //     id: 'contact',
    //     title: <FormattedMessage id="contact" />,
    //     type: 'collapse',
    //     icon: icons.IconNfc,
    //     children: [
    //         {
    //             id: 'c-card',
    //             title: <FormattedMessage id="cards" />,
    //             type: 'item',
    //             url: '/app/contact/c-card',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'c-list',
    //             title: <FormattedMessage id="list" />,
    //             type: 'item',
    //             url: '/app/contact/c-list',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'e-commerce',
    //     title: <FormattedMessage id="e-commerce" />,
    //     type: 'collapse',
    //     icon: icons.IconBasket,
    //     children: [
    //         {
    //             id: 'products',
    //             title: <FormattedMessage id="products" />,
    //             type: 'item',
    //             url: '/e-commerce/products'
    //         },
    //         {
    //             id: 'product-details',
    //             title: <FormattedMessage id="product-details" />,
    //             type: 'item',
    //             url: '/e-commerce/product-details/default',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'product-list',
    //             title: <FormattedMessage id="product-list" />,
    //             type: 'item',
    //             url: '/e-commerce/product-list',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'checkout',
    //             title: <FormattedMessage id="checkout" />,
    //             type: 'item',
    //             url: '/e-commerce/checkout'
    //         }
    //     ]
    // }
  ]
};

export default application;
