// action - state management
import {
  SET_CUSTOMER,
  REMOVE_CUSTOMER,
  LIST_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_WAREHOUSE,
  DELETE_CUSTOMER,
  DELETE_WAREHOUSE,
  ADD_CUSTOMER,
  ADD_WAREHOUSE,
  SET_WAREHOUSEOPTION,
  LIST_WAREHOUSE,
  ADD_COMPANY_DETAILS,
  ADD_COMPANY_MAPDATA,
  ADD_COMPANY_LOGO,
  SET_WAREHOUSELEVEL,
  SET_ZONELEVEL,
  SET_BASEPRICEVALUE,
  SET_ZONEVALUE,
  SET_COMIDVALUE,
  RESET_STORE
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //
const initialState = {
  row: [],
  _allDetails: [],
  _warehouseDetails: [],
  warehouse: '',
  companyDetails: null,
  companyLogo: '',
  warehouseLevel: null,
  zonelevel: null,
  basePrice: {},
  zoneValue: [],
  complayByIdData: []
};
const customerEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER: {
      const { row } = action.payload;
      return {
        ...state,
        row
      };
    }
    case REMOVE_CUSTOMER: {
      const { row } = action.payload;
      return {
        ...state,
        row
      };
    }
    case LIST_CUSTOMER: {
      return {
        ...state,
        _allDetails: action.list
      };
    }
    case LIST_WAREHOUSE: {
      return {
        ...state,
        _warehouseDetails: action.list
      };
    }
    case UPDATE_CUSTOMER: {
      return {
        ...state,
        _allDetails: state._allDetails.map(obj =>
          obj.id == action.payload.id ? action.payload : obj
        )
      };
    }
    case UPDATE_WAREHOUSE: {
      return {
        ...state,
        _warehouseDetails: state._warehouseDetails.map(obj =>
          obj.primaryKey == action.payload.primaryKey ? action.payload : obj
        )
      };
    }
    case DELETE_CUSTOMER: {
      return {
        ...state,
        _allDetails: state._allDetails.filter(record => record.id != action.payload)
      };
    }
    case DELETE_WAREHOUSE: {
      return {
        ...state,
        _warehouseDetails: state._warehouseDetails.filter(record => record.id != action.payload)
      };
    }
    case ADD_CUSTOMER: {
      return {
        ...state,
        _allDetails: [action.payload, ...state._allDetails]
      };
    }
    case ADD_WAREHOUSE: {
      return {
        ...state,
        _warehouseDetails: [action.payload, ...state._warehouseDetails]
      };
    }
    case SET_WAREHOUSEOPTION: {
      return {
        ...state,
        warehouse: action.payload
      };
    }
    case ADD_COMPANY_DETAILS: {
      return {
        ...state,
        companyDetails: action.payload
      };
    }
    case ADD_COMPANY_MAPDATA: {
      return {
        ...state,
        companyMapDetails: action.payload
      };
    }
    case ADD_COMPANY_LOGO: {
      return {
        ...state,
        companyLogo: action.payload
      };
    }
    case SET_WAREHOUSELEVEL: {
      return {
        ...state,
        warehouseLevel: action.payload
      };
    }
    case SET_ZONELEVEL: {
      return {
        ...state,
        zonelevel: action.payload
      };
    }
    case SET_BASEPRICEVALUE: {
      return {
        ...state,
        basePrice: action.payload
      };
    }
    case SET_ZONEVALUE: {
      return {
        ...state,
        zoneValue: action.payload
      };
    }
    case RESET_STORE: {
      return {
        ...state,
        basePrice: action.payload,
        zoneValue: action.payload
      };
    }
    case SET_COMIDVALUE: {
      return {
        ...state,
        complayByIdData: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default customerEditReducer;
