import usePermission from 'hooks/usePermission';
// ==============================|| MENU ITEMS ||============================== //

const MenuItems = userPermissions => {
  // eslint-disable-next-line no-unused-vars
  const [hasPermission, sideBarPermission] = usePermission();

  if (!userPermissions) {
    return {};
  }
  return {
    items: sideBarPermission
  };
};

export default MenuItems;
