// import PropTypes from 'prop-types';

// // material-ui
import { useMediaQuery } from '@mui/material';

// // third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import LeftArrow from '../../../assets/images/dashboard/leftArrow.svg';

// // project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';

// ==============================|| SIDEBAR DRAWER ||==============================
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const drawerWidth = 250;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

function Sidebar({ drawerOpen, drawerToggle }) {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const leftDrawerOpened = useSelector(state => state.customization.opened);
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={drawerOpen}
        container={container}
        sx={{
          '& .MuiPaper-root': {
            borderRight: 0,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
          }
        }}>
        <DrawerHeader sx={{ boxShadow: 'none' }}>
          {leftDrawerOpened && (
            <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
              <LogoSection />
            </Box>
          )}

          <IconButton onClick={drawerToggle}>
            <img
              src={LeftArrow}
              width={16}
              height={16}
              className={leftDrawerOpened ? 'rotate0' : 'rotateimg180'}
            />
          </IconButton>
        </DrawerHeader>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box sx={{ display: 'flex', p: 2, mx: 'auto', boxShadow: 'none' }}>
            <LogoSection />
          </Box>
        </Box>
        <BrowserView>
          <PerfectScrollbar
            component="div"
            style={{
              height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
              paddingLeft: leftDrawerOpened ? '20px' : '5px',
              paddingRight: leftDrawerOpened ? '20px' : '5px'
            }}>
            <MenuList />
            {/* <MenuCard /> */}
          </PerfectScrollbar>
        </BrowserView>
        <MobileView>
          <Box sx={{ px: 2 }}>
            <MenuList />
            {/* <MenuCard /> */}
          </Box>
        </MobileView>
      </Drawer>
    </Box>
  );
}
Sidebar.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  window: PropTypes.object
};
export default Sidebar;
