import axios from 'axios';
import { getSession } from 'views/pages/authentication/auth-forms/Accounts';
import { showErrorToaster } from 'ui-component/alertService';
import config from '../config';
import { Auth } from 'aws-amplify';
import { setAuthToken } from 'utils';

let activityTime;

const axiosApi = axios.create({
  baseURL: config.baseUrl,
  headers: { 'x-api-key': config.xApiKey }
});

async function isNotValidToken() {
  const session = await getSession();
  const { payload } = session.accessToken;
  const tokenGeneratedAt = payload.auth_time;
  const tokenExpirationTime = new Date((tokenGeneratedAt + 7200) * 1000);
  const timeNow = new Date().getTime();

  const isTokenExpired = timeNow >= tokenExpirationTime;

  return isTokenExpired;
}
const handleSessionExpireError = () => {
  localStorage.clear();
  setTimeout(() => {
    showErrorToaster('', 'Your session expired please login again.');
  }, 500);
  setTimeout(() => {
    window.location.href = '/login';
  }, 2500);
};
export const setAuthHeader = token => {
  axiosApi.defaults.headers.common.Authorization = `${token || localStorage.getItem('token')}`;
};

axiosApi.defaults.headers.common.Authorization = `${localStorage.getItem('token')}`;

const generateNewToken = async () => {
  // Async call to Cognito to check for token
  // This will be checked every time a protected route is loaded
  try {
    const response = await Auth.currentSession();
    const { idToken } = response;
    const jwtToken = idToken?.getJwtToken();
    setAuthToken(jwtToken);
    return jwtToken;
  } catch (error) {
    console.log(error);
  }
};

// if a 401 happens, the user will be logged out
axiosApi.interceptors.response.use(
  async function (response) {
    activityTime = new Date().getTime();
    return response;
  },
  async error => {
    const originalRequest = error.config;
    const currentTime = new Date().getTime();
    const inactivityThreshold = 120 * 60 * 1000; // 120 minutes in milliseconds
    if (currentTime - activityTime < inactivityThreshold) {
      originalRequest._retry = true;

      axios.interceptors.response.eject();
      const tokenRes = await generateNewToken();
      originalRequest.headers.Authorization = tokenRes;
      return axios(originalRequest);
    }
    // if session is expired it will show error and redirect to login page
    const isNotValidToken_ = await isNotValidToken();
    if (isNotValidToken_) {
      handleSessionExpireError();
    }
    if (error?.response?.status === 401) {
      handleSessionExpireError();
    }
    return Promise.reject(error);
  }
);

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(localStorage.getItem('token'));

export default axiosApi;
