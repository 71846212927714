export const CARRIERS = [
  { name: 'CDL', value: 'CDL' },
  { name: 'ShipX', value: 'shipX-carrier' },
  { name: 'GoExpress', value: 'SJO001' },
  { name: 'StatOvernight', value: 'DFW001' },
  { name: 'GIOExpress', value: 'NWK001' },
  { name: 'BetterTrucks', value: 'DFW001' }
];

export const FILTER_SHIPMENT_STATUS_CODES = {
  Voided: 'Voided',
  InProcess: 'InProcess',
  Delivered: 'Delivered',
  Exception: 'Exception'
};
export const STATUS_CODES = {
  InProcess: 'InProcess',
  Voided: 'Voided',
  Exception: 'Exception',
  Returned: 'Returned',
  Labelled: 'Labelled',
  Loaded: 'Loaded',
  Unloaded: 'Unloaded',
  Sort: 'Sort',
  InTransit: 'Intransit',
  Received: 'Received',
  OutForDelivery: 'Out For Delivery',
  Delivered: 'Delivered',
  Request: 'Open Request'
  // Parcel: 'Parcel',
  // Pallets: 'Pallets'
};

export const SHIPMENT_TYPE = {
  Parcel: 'Parcel',
  Pallets: 'Pallets'
};

export const graphViewTypes = {
  day: 'day',
  week: 'week',
  month: 'month',
  custom: 'custom'
};

export const STATUS_CODES_COLOR = {
  InProcess: 'rgb(47,88,156)',
  Voided: 'rgb(0,0,0)',
  Exception: 'rgb(255, 0, 0)',
  Returned: 'rgb(255, 106, 16)',
  Labelled: 'rgb(47,88,156)',
  Loaded: 'rgb(0, 114, 188)',
  Unloaded: 'rgb(0, 140, 204)',
  Sort: 'rgb(0, 164, 197)',
  Received: 'rgb(0,185,167)',
  InTransit: 'rgb(0,255,255)',
  Intransit: 'rgb(0,255,255)',
  Outfordelivery: 'rgb(0,204,120)',
  OutForDelivery: 'rgb(0,204,120)',
  Delivered: 'rgb(44,218,53)',
  Shipment: 'rgb(221,221,221)',
  Pallet: 'rgb(221,221,221)',
  Open: 'rgb(217,204,239)',
  Request: 'rgb(217,204,239)'
};

export const summaryStatuses = {
  InProcess: 'InProcess',
  Voided: 'Voided',
  Exception: 'Exception',
  Returned: 'Returned',
  Labelled: 'Labelled',
  Loaded: 'Loaded',
  Unloaded: 'Unloaded',
  Sort: 'Sort',
  InTransit: 'Intransit',
  Received: 'Received',
  OutForDelivery: 'Out For Delivery',
  Delivered: 'Delivered',
  onTime: 'onTime',
  late_1: 'late_1',
  late_2: 'late_2',
  late_5: 'late_5',
  late_gt_5: 'late_gt_5',
  Request: 'Open Request'
  // Parcel: 'Parcel',
  // Pallets: 'Pallets'
};
