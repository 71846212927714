// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Typography } from '@mui/material';
import { LocalShipping } from 'assets/images/icons';

// constant
const icons = {
  LocalShipping
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const sideBarOperations = {
  id: 'sideBarOperations',
  title: <FormattedMessage id="operations" />,
  type: 'group',
  children: [
    {
      id: 'sort-shipment',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="Sort Shipment" />
        </Typography>
      ),
      type: 'item',
      url: '/operations/sort',
      icon: icons.LocalShipping,
      breadcrumbs: false
    }
  ]
};
export default sideBarOperations;
