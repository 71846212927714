import PropTypes from 'prop-types';

// material-ui
import { Box } from '@mui/material';

// project imports
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import IconButton from '@mui/material/IconButton';

// assets
import { useSelector } from 'react-redux';
// import { accessSearchBar } from 'utils';
import usePermission from 'hooks/usePermission';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTableData from '../../../views/application/customer/useTableData';
import { showErrorToaster } from 'ui-component/alertService';
import MenuIcon from '@mui/icons-material/Menu';
import { getCompanyByName } from 'containers/Company/action';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const [hasPermission] = usePermission();
  const userData = useSelector(state => state?.user?.userData);
  const leftDrawerOpened = useSelector(state => state.customization.opened);
  const userPermissions = userData?.userPermissions;
  const userCompanyName = userData && userData['custom:company'];

  const { setCompanyDetails } = useTableData();
  useEffect(async () => {
    if (!userCompanyName) return;
    await getCompanyByName(userCompanyName)
      .then(async res => {
        setCompanyDetails(res?.data[0], 'AddCompanyDetails');
        setCompanyDetails(res?.data[0]);
      })
      .catch(err => {
        localStorage.clear();
        navigate('login');
        showErrorToaster('', 'Your session expired please login again.');
        console.log('getCompanyByName : ', err);
      });
  }, [userCompanyName]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: leftDrawerOpened ? 228 : 45,
          display: 'flex',
          mr: 1,
          pr: 1
        }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' })
          }}>
          <MenuIcon />
        </IconButton>
      </Box>
      {/* header search */}
      {/* Search and logo will appear to permission granted user */}
      {hasPermission('Search text box') && (
        <>
          <SearchSection userPermissions={userPermissions} />
        </>
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <ProfileSection />
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        <MobileSection />
      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
