import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister = Loadable(
  lazy(() => import('views/pages/authentication/authentication/Register'))
);
const ForgotPassword = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/ResetPassword'))
);
const VerifyEmail = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/VerifyEmail'))
);
const RequestVerifyingMail = Loadable(
  lazy(() => import('views/pages/authentication/auth-forms/RequestVerifyingMail'))
);
const AuthCompany = Loadable(lazy(() => import('views/pages/company')));
const AuthReviewSubmission = Loadable(lazy(() => import('views/pages/company/review-submission')));
const AuthFinalReview = Loadable(lazy(() => import('views/pages/company/final-review')));
const AuthWarehouseLevel = Loadable(lazy(() => import('views/pages/company/warehouseLevel')));

const AuthenticationRoutes = () => {
  return {
    path: '/',
    element: <MinimalLayout />,
    children: [
      {
        path: '/login',
        element: <AuthLogin />
      },
      {
        path: '/register',
        element: <AuthRegister />
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: '/reset-password',
        element: <ResetPassword />
      },
      {
        path: '/verify-email',
        element: <VerifyEmail />
      },
      {
        path: '/request-verifying-mail',
        element: <RequestVerifyingMail />
      },
      {
        path: '/company',
        element: <AuthCompany />
      },
      {
        path: '/review',
        element: <AuthReviewSubmission />
      },
      {
        path: '/final-review',
        element: <AuthFinalReview />
      },
      {
        path: '/warehouse-level',
        element: <AuthWarehouseLevel />
      },
      {
        path: '/pages/login/login1',
        element: <AuthLogin />
      },
      {
        path: '/pages/register/register1',
        element: <AuthRegister />
      }
    ]
  };
};

export default AuthenticationRoutes;
