// action - state management
import { SET_CHIP_SUMMARY } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  chipSummaryData: []
};

const shipmentSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHIP_SUMMARY: {
      return {
        ...state,
        chipSummaryData: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default shipmentSummaryReducer;
