// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Typography } from '@mui/material';
import { contactList, InfoFaq, HeadPhone } from 'assets/images/icons';

// constant
const icons = {
  contactList,
  InfoFaq,
  HeadPhone
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const HelpCenter = {
  id: 'helpcenter',
  title: <FormattedMessage id="helpcenter" />,
  type: 'group',
  children: [
    {
      id: 'contactlist',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="contactlist" />
        </Typography>
      ),
      type: 'item',
      url: '/dashboard/contact-list',
      icon: icons.contactList,
      breadcrumbs: false
    }
    /*,{
      id: 'faq',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="faq" />
        </Typography>
      ),
      type: 'item',
      url: '/dashboard/helpcenter',
      icon: icons.InfoFaq,
      breadcrumbs: false
    },
    {
      id: 'support',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="support" />
        </Typography>
      ),
      type: 'item',
      url: '/dashboard/support',
      icon: icons.HeadPhone,
      breadcrumbs: false
    }*/
  ]
};
export default HelpCenter;
