// action - state management
import { clearLocalStorage, setItemToLocalStorage } from 'utils';
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  ISNEWUSER,
  USER_GROUP,
  USER_COMPANY,
  SET_PERMISSIONS,
  USERROLE,
  UPDATE_FILL_COMPANYDETAIL_BOOLEAN_VALUE,
  IS_ADMIN_CHANGE_COMPANY
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //
const initialState = {
  userData: {},
  userCompany: {},
  adminChangeCompany: false
};
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }
    case ISNEWUSER: {
      return {
        ...state,
        isNewUser: action?.payload
      };
    }
    // Note: Always use LOGIN action before any account related dispatching things
    case LOGIN: {
      const userData = action?.payload;
      const userRole = (userData?.['cognito:groups'] && userData?.['cognito:groups'][0]) || [];
      return {
        ...state,
        userData,
        isLoggedIn: true,
        isInitialized: true,
        userCognitoGroup: userRole
      };
    }
    case UPDATE_FILL_COMPANYDETAIL_BOOLEAN_VALUE: {
      return {
        ...state,
        userData: { ...state.userData, ...action?.payload }
      };
    }
    case 'AddCompanyDetails': {
      const userCompany = action?.payload;
      return {
        ...state,
        userData: { ...state.userData, userCompany }
      };
    }
    case USER_COMPANY: {
      return {
        ...state,
        userCompany: action?.payload
      };
    }
    case 'selectedCompany': {
      const companyDetails = action?.payload;
      return {
        ...state,
        userData: { ...state.userData, companyDetails }
      };
    }
    case IS_ADMIN_CHANGE_COMPANY: {
      return {
        ...state,
        adminChangeCompany: action.payload
      };
    }
    case LOGOUT: {
      clearLocalStorage();
      return {
        ...state,
        userData: {},
        isInitialized: true,
        isLoggedIn: false,
        user: null
      };
    }
    case USER_GROUP: {
      const userGroups = action.payload;
      const userData = state.userData;
      setItemToLocalStorage('userGroups', JSON.stringify(userGroups));
      return {
        ...state,
        userData: { ...userData, userGroups }
      };
    }
    case SET_PERMISSIONS: {
      const userData = state.userData;
      return {
        ...state,
        userData: { ...userData, userPermissions: action.payload }
      };
    }
    case USERROLE: {
      const { users, ...rest } = action.payload;
      const userData = { ...state };
      if (users && Object.keys(users.userData).length > 0 && !userData?.users) {
        userData.users = users;
      }

      return {
        ...userData,
        ...rest
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
