// action - state management
import {
  SET_CONTACT_LIST,
  CLEAR_CONTACT_LIST,
  SET_CONTACT_LIST_ROW_LENGTH,
  SET_CONTACT_LIST_PAGE_NUMBER,
  SET_CONTACT_LIST_LAST_PAGE,
  SET_CONTACT_LIST_PAGE_KEYS,
  SET_CONTACT_LIST_PARAMS
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  contactList: [],
  page_keys: [],
  rowsPerPage: 5,
  page: 1,
  lastPage: false
};

const contactDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_LIST: {
      return {
        ...state,
        contactList: [...state.contactList, ...action.payload]
      };
    }
    case CLEAR_CONTACT_LIST: {
      return {
        ...state,
        contactList: []
      };
    }
    case SET_CONTACT_LIST_ROW_LENGTH: {
      return {
        ...state,
        rowsPerPage: action.payload
      };
    }
    case SET_CONTACT_LIST_PAGE_NUMBER: {
      return {
        ...state,
        page: action.payload
      };
    }
    case SET_CONTACT_LIST_LAST_PAGE: {
      return {
        ...state,
        lastPage: action.payload
      };
    }
    case SET_CONTACT_LIST_PAGE_KEYS: {
      return {
        ...state,
        page_keys: action.payload
      };
    }
    case SET_CONTACT_LIST_PARAMS: {
      return {
        ...state,
        params: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default contactDataReducer;
