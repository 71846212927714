import config from 'config.js';
import { deleteShipmentById, voidShipment, voidPackage } from 'containers/Shipments/action';
import { showErrorToaster, showSuccessToaster } from 'ui-component/alertService';
import { logout } from 'views/pages/authentication/auth-forms/Accounts';
import { setAuthHeader } from '_mockApis/axiosApi';
import { STATUS_CODES_COLOR } from './sample-json/shipmentObjects';

const { userRoleKey } = config;

const historyReplaceState = (state, title, path) => {
  const history = window.history;
  history.replaceState(state, '', path);
};

const handleDeleteShipment = (data, handleLoader, handleClose) => {
  handleLoader(true);
  for (let i = 0; i < data.length; i++) {
    const params = data[i];
    deleteShipmentById(params)
      .then(() => {
        showSuccessToaster(`${data.length} Record Deleted successfully.`);
        handleClose(data);
        return data;
      })
      .catch(err => {
        showErrorToaster(err, 'Spmehing is wrong. Please try again.');
        console.log({ err });
      });
  }
};

const handleVoidShipment = row => {
  return voidShipment(row);
};

const handleVoidPackage = (row, id) => {
  return voidPackage(row, id);
};
const setAuthToken = jwtToken => {
  localStorage.setItem('token', jwtToken);
  setAuthHeader(jwtToken);
};
const clearLocalStorage = () => {
  localStorage.clear();
};
const setItemToLocalStorage = (name, data) => {
  localStorage.setItem(name, data);
};
const getItemFromLocalStorage = key => {
  return localStorage.getItem(key);
};
const getUserRoleBasedPermission = (userGroups, isShipXUser) => {
  const initialPermission = {
    role: userRoleKey.OPERATIONS_ADMIN,
    shipment: true,
    operations: true,
    admin: false
  };
  if (isShipXUser) {
    initialPermission.role = userRoleKey.SHIPX;
    initialPermission.shipment = true;
    initialPermission.operations = true;
    initialPermission.admin = true;
    localStorage.setItem('initialPermission', initialPermission.role);
    return initialPermission;
  }
  const { cognitoUserGroupName } = config;
  switch (userGroups[0]) {
    case cognitoUserGroupName.OPERATIONS_ADMIN:
      initialPermission.role = userRoleKey.OPERATIONS_ADMIN;
      initialPermission.shipment = true;
      initialPermission.operations = true;
      // This changed to true for admin but ADMIN will only able to access information of their company only
      initialPermission.admin = true;
      return initialPermission;
    case cognitoUserGroupName.ASSOCIATE:
      initialPermission.role = userRoleKey.ASSOCIATE;
      initialPermission.shipment = false;
      initialPermission.operations = true;
      initialPermission.admin = false;
      return initialPermission;
    case cognitoUserGroupName.CUSTOMER:
      initialPermission.role = userRoleKey.CUSTOMER;
      initialPermission.shipment = true;
      initialPermission.operations = false;
      initialPermission.admin = false;
      return initialPermission;
    case cognitoUserGroupName.ADD_COMPANY_DETAILS:
      initialPermission.role = userRoleKey.ADD_COMPANY_DETAILS;
      initialPermission.shipment = false;
      initialPermission.operations = false;
      initialPermission.admin = false;
      return initialPermission;
    case cognitoUserGroupName.CUSTOMER_ASSOCIATE:
      initialPermission.role = userRoleKey.CUSTOMER_ASSOCIATE;
      initialPermission.shipment = true;
      initialPermission.operations = false;
      initialPermission.admin = false;
      return initialPermission;
    case cognitoUserGroupName.CUSTOMER_ADMIN:
      initialPermission.role = userRoleKey.CUSTOMER_ADMIN;
      initialPermission.shipment = false;
      initialPermission.operations = false;
      initialPermission.admin = false;
      return initialPermission;
    default:
      return initialPermission;
  }
};

const accessSearchBar = [
  userRoleKey.OPERATIONS_ADMIN,
  userRoleKey.SHIPX,
  userRoleKey.CUSTOMER_ADMIN,
  userRoleKey.CUSTOMER
];

const isAuthenticatedUserShipxUser = payload => {
  const userCompanyName = payload['custom:company'];
  if (userCompanyName && userCompanyName === config.cognitoUserGroupName.SHIPX) {
    return true;
  }
  return false;
};

// This function return flag value based on company details If company details are not filled up then it will return false
const checkCompanyDetails = companyDetails => {
  if (
    companyDetails?.address_line1 &&
    companyDetails?.city_locality &&
    companyDetails?.country_code &&
    companyDetails?.phone &&
    companyDetails?.email
  ) {
    return true;
  }
  return false;
};
const isUserPartOfaGroup = async (groups, history) => {
  if (!groups?.length) {
    await logout(history);
    return false;
  }
  return true;
};

const downloadBase64 = (data, type, base64StartingString) => {
  // type should be data type ForEx: if there is pdf form decoded data then it should be pdf
  // base64StartingString should be string for attaching in start of base64 encoded data to view in diff form like pdf, jpg ect.
  const d = new Date();
  const fileName = d.valueOf();
  const link = document.createElement('a');
  link.download = `${fileName}.${type}`;
  link.href = base64StartingString + data;
  link.click();
};
const b64toBlob = (content, contentType) => {
  contentType = contentType || '';
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
    type: contentType
  }); // statement which creates the blob
  return blob;
};

// this function will show label preview in pdf of label and options for printing it
const printPreview = (data, type = 'application/pdf') => {
  let blob = null;
  blob = b64toBlob(data, type);
  const blobURL = URL.createObjectURL(blob);
  const theWindow = window.open(blobURL);
  const theDoc = theWindow.document;
  const theScript = document.createElement('script');
  function injectThis() {
    window.print();
  }
  theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
  theDoc.body.appendChild(theScript);
};
const signature_require_options = {
  Over21: 'Over21',
  Over18: 'Over18',
  Any: 'Any',
  Recipient: 'Recipient',
  None: 'None'
};

const showStatus = (status, DataType) => {
  let type = status?.status_code || ''; // this is for shipment status
  if (DataType === 'package') {
    type = status?.activity_code; // this is for package status
  }
  return (
    <>
      <span
        className="circle"
        style={{
          background: `${STATUS_CODES_COLOR[type]}`
        }}></span>
      <span
        className="shipment-status"
        style={{
          color: `${STATUS_CODES_COLOR[type]}`
        }}>
        {status?.code.replace('Shipment', '')}
      </span>
    </>
  );
};
const getGreetingBasedOnTime = () => {
  const today = new Date();
  const currentHR = today.getHours();
  let greetingMessage = '';
  if (currentHR >= 0 && currentHR < 5) {
    greetingMessage = "It's night...";
  } else if (currentHR >= 5 && currentHR <= 12) {
    greetingMessage = 'Good Morning';
  } else if (currentHR >= 12 && currentHR < 17) {
    greetingMessage = 'Good Afternoon';
  } else {
    greetingMessage = 'Good Evening';
  }
  return greetingMessage;
};
const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  result = new Date(result);
  return result;
};
const { cognitoUserGroupName } = config;
const roleName = [
  cognitoUserGroupName.ADMIN,
  cognitoUserGroupName.CUSTOMER,
  cognitoUserGroupName.SHIPPER_ADMIN,
  cognitoUserGroupName.SHIPPER_ASSOCIATE,
  cognitoUserGroupName.CARRIER_ADMIN,
  cognitoUserGroupName.CARRIER_ASSOCIATE,
  cognitoUserGroupName.OPERATIONS_ADMIN,
  cognitoUserGroupName.ASSOCIATE
];

function get(object, path, defaultValue) {
  const keys = path.split('.');
  let value = object;

  for (let key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return defaultValue;
    }
  }

  return value !== undefined ? value : defaultValue;
}
export {
  get,
  historyReplaceState,
  handleDeleteShipment,
  handleVoidPackage,
  handleVoidShipment,
  setAuthToken,
  setItemToLocalStorage,
  clearLocalStorage,
  getItemFromLocalStorage,
  getUserRoleBasedPermission,
  isAuthenticatedUserShipxUser,
  isUserPartOfaGroup,
  downloadBase64,
  showStatus,
  signature_require_options,
  printPreview,
  checkCompanyDetails,
  accessSearchBar,
  getGreetingBasedOnTime,
  addDays,
  roleName
};
