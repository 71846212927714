import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase
    disableRipple
    component={Link}
    to={config.defaultRouteForUserRole.CUSTOMER}
    style={{ display: 'flex' }}>
    <Logo width="105px" alt="ShipX-Logo" />
  </ButtonBase>
);

export default LogoSection;
