import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../views/pages/authentication/auth-forms/Accounts';
// material-ui
import { useTheme } from '@mui/material/styles';
import usePermission from 'hooks/usePermission';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Card
} from '@mui/material';
import { LogoutIcon } from 'assets/images/icons';

// third-party

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { LOGOUT } from 'store/actions';
import { getGreetingBasedOnTime, roleName } from 'utils';
import * as actionTypes from '../../../../store/actions';
import { map } from 'lodash';
import useTableData from '../../../../views/application/customer/useTableData';
import { getCompanyLogoUrl } from 'containers/Company/action';

// ==============================|| PROFILE MENU ||============================== //
let companyDetails = {};
const ProfileSection = () => {
  const theme = useTheme();
  const { setCompanyDetails } = useTableData();
  const customization = useSelector(state => state.customization);
  const { userData } = useSelector(state => state?.user);
  const [hasPermission] = usePermission();
  const role = useSelector(state => state?.user?.userCognitoGroup);
  const adminRole = useSelector(state => state?.user?.userData?.['cognito:groups']);
  const allCompanyData = useSelector(state => state.company.companyList) || [];
  const user = JSON.parse(localStorage.getItem('user'));
  const admin = adminRole?.length && adminRole[0];
  const navigate = useNavigate();
  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState(role);
  const [companyData, setCompanyData] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(-1);
  const [previewImage, setPreviewImage] = useState('');
  const [userRoleData, setUserRoleData] = useState(roleName);
  const [loginUserRole, setLoginUserRole] = useState();
  const [companyHasType, setCompanyHasType] = useState(false);
  const userCompanyDetails = useSelector(state => state?.user?.userCompany) || {};
  companyDetails =
    userCompanyDetails && Object.keys(userCompanyDetails).length > 0
      ? userCompanyDetails
      : userData?.companyDetails;
  const dispatch = useDispatch();
  const users = useSelector(state => state?.user);

  useEffect(() => {
    if (user && allCompanyData?.length) {
      let companyArr;
      if (user['custom:company']?.toLowerCase() == 'shipx') {
        companyArr = allCompanyData;
      } else {
        companyArr = allCompanyData.filter(obj => {
          return obj.company_name?.toLowerCase() == user['custom:company'].toLowerCase();
        });
      }
      setCompanyData(
        companyArr?.sort((a, b) => {
          a?.company_name?.localeCompare(b.company_name);
        })
      );
    }
  }, [allCompanyData]);

  useEffect(() => {
    let initilRoles = [];
    if (!users?.users) {
      if (hasPermission('customer-service') || users?.userCognitoGroup === 'customer-service') {
        initilRoles = roleName.filter(role => role !== 'admin');
      } else {
        initilRoles = roleName;
      }
      setUserRoleData(initilRoles);
      setUserName(initilRoles[0]);
      setLoginUserRole(initilRoles[0]);
      dispatch({
        type: actionTypes.USERROLE,
        payload: { userCognitoGroup: initilRoles[0], users: users }
      });
    }
  }, [users?.userCognitoGroup]);

  useEffect(() => {
    if (companyDetails) {
      setSelectedCompany(companyDetails?.id);
      handleCompanyLogo(companyDetails);
    }
    if (userCompanyDetails && Object.keys(userCompanyDetails).length === 0) {
      dispatch({
        type: actionTypes.USER_COMPANY,
        payload: companyDetails
      });
    }
  }, [companyDetails]);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    dispatch({ type: LOGOUT });
    logout(navigate);
  };
  const getUserName = () => {
    return `${userData?.name || ''} ${userData?.family_name || ''}` || 'admin';
  };

  const stringAvatar = (name, familyname) => {
    return {
      children:
        `${name?.split(' ')[0][0]?.toUpperCase()}` +
        (familyname && `${familyname?.split(' ')[0][0]?.toUpperCase()}`)
    };
  };
  const handleClose = event => {
    if (event.target.localName === 'body') {
      return;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleChange = event => {
    setUserName(event.target.value);
    dispatch({
      type: actionTypes.USERROLE,
      payload: { userCognitoGroup: event.target.value, users: users }
    });
  };

  const handleChangeCompany = async ({ target }) => {
    setSelectedCompany(target.value);
    dispatch({
      type: actionTypes.CLEAR_DASHBOARD_SHIPMENT_LIST,
      payload: []
    });
    dispatch({
      type: actionTypes.IS_ADMIN_CHANGE_COMPANY,
      payload: true
    });
    dispatch({
      type: actionTypes.CLEAR_SHIPMENT_LIST,
      payload: []
    });
    if (companyData) {
      let suitableRole = [];
      const company = companyData?.find(data => data.id === target.value);
      if (company?.type) {
        setCompanyHasType(false);
        if (company.company_name === 'ShipX' && loginUserRole === 'admin') {
          suitableRole = roleName;
        } else if (company.company_name === 'ShipX' && loginUserRole === 'customer-service') {
          suitableRole = roleName.filter(role => role !== 'admin');
        } else {
          suitableRole = roleName?.filter(a => a.includes(company?.type));
        }
        setUserRoleData(suitableRole);
        setUserName(suitableRole[0]);
        dispatch({
          type: actionTypes.USERROLE,
          payload: { userCognitoGroup: suitableRole[0], users: users }
        });
      } else {
        setCompanyHasType(true);
      }
      setCompanyDetails(company);
      await handleCompanyLogo(company);
    }
  };

  const handleCompanyLogo = async companyDetails => {
    if (companyDetails?.company_logo) {
      const companyLogo = await getCompanyLogoUrl(companyDetails?.company_logo);
      if (companyLogo?.data?.company_logo && !companyLogo?.data?.message) {
        setPreviewImage(companyLogo?.data?.company_logo);
      }
    } else {
      setPreviewImage('');
    }
  };

  const Icon = LogoutIcon;

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          border: '1px solid',
          color: theme.palette.primary.light,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : '#B0D900',
          backgroundColor: '#FFFFFF',
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: '#B0D900',
            background: `${theme.palette.bluegrey[100]}!important`,
            color: theme.palette.primary.light
          },
          '& .MuiChip-label': {
            lineHeight: 0,
            paddingLeft: 0,
            paddingRight: 0
          },
          '& .MuiChip-icon': {
            margin: '6px'
          }
        }}
        icon={
          <Avatar
            // src={User1}
            {...stringAvatar(userData?.name, userData?.family_name || '')}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '6 !important',
              cursor: 'pointer',
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: '700',
              backgroundColor: '#F9B03A'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}>
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ px: 1, pt: 1, pb: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{getGreetingBasedOnTime()},</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {getUserName()}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          mt: '4px',
                          mb: 1,
                          fontSize: '14px',
                          fontWeight: 500,
                          color: '#9E9E9E',
                          lineHeight: '17px'
                        }}>
                        {role ? role : userName}
                      </Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  {['admin', 'customer-service'].includes(admin) && (
                    <>
                      <Box sx={{ p: 2, backgroundColor: '#eceff1', pb: 1 }}>
                        <Card sm={6} sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                          <Box
                            alignItems="center"
                            display="flex"
                            sx={{ height: '58px', width: '87px' }}>
                            <img
                              src={previewImage || './../../../images/placeholderImage.png'}
                              alt="selected company logo"
                              className="selected-comp-logo"
                            />
                          </Box>
                          <Box sx={{ minWidth: 150 }}>
                            <FormControl
                              sx={{
                                minWidth: 150,
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none'
                                }
                              }}>
                              <Select
                                value={selectedCompany}
                                onChange={handleChangeCompany}
                                displayEmpty
                                name="company"
                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                sx={{
                                  fontSize: '20px',
                                  width: '215px',
                                  '& .MuiSelect-select': { background: 'none', maxWidth: '320px' }
                                }}>
                                {map(companyData, item => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {item?.company_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Card>
                        {!companyHasType && (
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <FormControl sx={{ my: 1, minWidth: 308, pt: '14px' }} size="small">
                              <Select
                                key={userName}
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={role ? role : userName}
                                defaultValue={role}
                                onChange={handleChange}>
                                {userRoleData.map(name => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                  {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                  <Box sx={{ p: 1 }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        paddingTop: '10px',
                        paddingBottom: '10px'
                      }}>
                      <ListItemButton
                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                        // selected={selectedIndex === 4}
                        onClick={handleLogout}>
                        <ListItemIcon sx={{ minWidth: '27px' }}>
                          <Icon stroke={1.5} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: '16px',
                                fontWeight: 500,
                                color: '#9E9E9E',
                                lineHeight: '19px'
                              }}>
                              Logout
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
