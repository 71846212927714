// action - state management
import {
  SET_USER_LIST,
  CLEAR_USER_LIST,
  SET_USER_LIST_CREATED_USER,
  SET_USER_LIST_TOKEN,
  SET_USER_LIST_UPDATED_USER
} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //
const initialState = {
  userList: [],
  userListToken: '',
  rowsPerPage: 5,
  page: 0
};

const shipmentsDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LIST: {
      return { ...state, userList: [...state.userList, ...action.payload] };
    }
    case SET_USER_LIST_TOKEN: {
      return {
        ...state,
        userListToken: action.payload
      };
    }
    case CLEAR_USER_LIST: {
      return {
        ...state,
        userList: []
      };
    }
    case SET_USER_LIST_UPDATED_USER: {
      const userList_ = [...state.userList].map(item => {
        if (item.UserAttributes.sub === action.payload.UserAttributes.sub) {
          item = { ...item };
          item = { ...item, ...action.payload };
          return item;
        }
        return item;
      });
      return {
        ...state,
        userList: [...userList_]
      };
    }
    case SET_USER_LIST_CREATED_USER: {
      const userList_ = [{ ...action.payload }];
      userList_.push(...state.userList);
      return {
        ...state,
        userList: [...userList_]
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default shipmentsDataReducer;
