import { useSelector } from 'react-redux';
import sideBarAdmin from '../menu-items/sideBarAdmin';
// import sideBarShipperAdmin from '../menu-items/sideBarShipperAdmin';
import sideBarOperations from '../menu-items/sideBarOperations';
import sideBarShipment from '../menu-items/sideBarShipment';
import HelpCenter from 'menu-items/helpCenter';
import sideBarAdminWithCompany from 'menu-items/sideBarAdminWithCompany';
import config from 'config.js';
// import dashboardUser from '../menu-items/dashboardUser';
const { cognitoUserGroupName } = config;
const PERMISSIONS = {
  [cognitoUserGroupName.SHIPPER_ADMIN]: [
    'shipper',
    'Search text box',
    'Filter',
    'filterStatus',
    'filterWarehouse',
    'FilterShipmentType',
    'Export',
    'Search',
    'Company Selector',
    'Company Code Remove',
    'Warehouse Selector',
    'View',
    'Delete',
    'Add',
    'carrierwarehouse',
    'dashboard graph',
    'PrintCsv',
    'FilterShipmentType',
    'editCompanyScreen',
    'createPalletOption',
    'companyandwarehouseblankfield',
    'voidShipment'
  ],
  [cognitoUserGroupName.SHIPPER_ASSOCIATE]: [
    'shipper',
    'Search text box',
    'Filter',
    'filterStatus',
    'filterWarehouse',
    'Company Selector',
    'FilterShipmentType',
    'Export',
    'Search',
    'View',
    'PrintCsv',
    'ChipSummaryCard'
  ],
  [cognitoUserGroupName.CARRIER_ADMIN]: [
    'carrier',
    'Search text box',
    'Filter',
    'filterWarehouse',
    'filterStatus',
    'filterWarehouse',
    'Company Selector',
    'Company Code Remove',
    'FilterShipmentType',
    'Export',
    'Search',
    'PrintCsv',
    'View',
    'editCompanyScreen',
    'credentials',
    'Delete',
    'editCompanyScreen',
    'voidShipment'
  ],
  [cognitoUserGroupName.CARRIER_ASSOCIATE]: [
    'carrier',
    'Search text box',
    'Filter',
    'filterStatus',
    'Company Selector',
    'filterWarehouse',
    'FilterShipmentType',
    'Export',
    'Search',
    'PrintCsv',
    'View'
  ],
  [cognitoUserGroupName.OPERATIONS_ADMIN]: [
    'Search text box',
    'Filter',
    'filterStatus',
    'filterWarehouse',
    'FilterShipmentType',
    'Company Selector',
    'Company Code Remove',
    'Export',
    'Search',
    'PrintCsv',
    'View',
    'selectedCompany',
    'createPalletOption',
    'editCompanyScreen'
  ],
  [cognitoUserGroupName.ASSOCIATE]: [
    'associate',
    'Search text box',
    'Filter',
    'filterStatus',
    'filterWarehouse',
    'FilterShipmentType',
    'Company Selector',
    'Export',
    'Search',
    'PrintCsv',
    'View'
  ],
  [cognitoUserGroupName.CUSTOMER]: [
    'custom-service',
    'customer-service',
    'shipmentListTableColumn',
    'Search text box',
    'Filter',
    'filterCompany',
    'filterWarehouse',
    'filterStatus',
    'filterCarrier',
    'Export',
    'Search',
    'Company Selector',
    'Warehouse Selector',
    'View',
    'Delete',
    'Add',
    'carrierwarehouse',
    'dashboard graph',
    'PrintCsv',
    'selectedCompany',
    'FilterShipmentType',
    'editCompanyScreen',
    'createNewCompany',
    'editCompanyScreen',
    'createPalletOption',
    'servicesAtCompanyInfo',
    'AllCompany',
    'allShipments',
    'allCompanyData',
    'carrierSelection',
    'navigateToCompanyList',
    'voidShipment'
  ],
  admin: [
    'admin',
    'shipmentListTableColumn',
    'Search text box',
    'Filter',
    'filterCompany',
    'filterWarehouse',
    'filterStatus',
    'filterCarrier',
    'Export',
    'Search',
    'Company Selector',
    'Warehouse Selector',
    'View',
    'Delete',
    'Add',
    'carrierwarehouse',
    'dashboard graph',
    'PrintCsv',
    'FilterShipmentType',
    'AllCompany',
    'selectedCompany',
    'createPalletOption',
    'allShipments',
    'carrierSelection',
    'allCompanyData',
    'servicesAtCompanyInfo',
    'createNewCompany',
    'navigateToCompanyList',
    'credentials',
    'voidShipment'
  ]
};

const usePermission = () => {
  const sideBarPermission = {
    [cognitoUserGroupName.SHIPPER_ADMIN]: [sideBarShipment, sideBarAdminWithCompany],
    [cognitoUserGroupName.SHIPPER_ASSOCIATE]: [sideBarShipment],
    [cognitoUserGroupName.CARRIER_ADMIN]: [sideBarShipment, sideBarAdminWithCompany],
    [cognitoUserGroupName.CARRIER_ASSOCIATE]: [sideBarShipment],
    [cognitoUserGroupName.OPERATIONS_ADMIN]: [
      sideBarShipment,
      sideBarOperations,
      sideBarAdminWithCompany
    ],
    [cognitoUserGroupName.ASSOCIATE]: [sideBarShipment, sideBarOperations],
    [cognitoUserGroupName.CUSTOMER]: [sideBarShipment, sideBarOperations, sideBarAdmin, HelpCenter],
    admin: [sideBarShipment, sideBarOperations, sideBarAdmin, HelpCenter]
  };
  const role = useSelector(state => state?.user?.userCognitoGroup);
  const hasPermission = permissions => {
    return role && permissions && PERMISSIONS[role]?.includes(permissions);
  };
  return [hasPermission, sideBarPermission[role]];
};

export default usePermission;
