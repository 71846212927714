import axiosApi from '../../_mockApis/axiosApi';
import { format } from 'date-fns';
// import { getFormattedDate } from '../../utils/getFormattedDate';

const getShipmentById = id => {
  return axiosApi({
    method: 'get',
    url: `/shipments/shipment?shipment_id=${id}`
  });
};

const exportShipmentCSV = ({
  days,
  start_date,
  end_date,
  sKey = '',
  carrier = '',
  status = false,
  removed_status = []
}) => {
  let startDate = new Date();
  let endDate = new Date();
  if (window.location.pathname !== '/dashboard/default') {
    startDate.setDate(startDate.getDate() - days);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    endDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  }

  const startDateString =
    window.location.pathname === '/dashboard/default' ? start_date : startDate;
  const endDateString = window.location.pathname === '/dashboard/default' ? end_date : endDate;

  let url = `shipments/prepareCSVForByDateRange?start_date=${startDateString}&end_date=${endDateString}&company_id=${sKey}&carrier=${carrier}`;
  if (removed_status?.length) url = `${url}&removed_status=${removed_status}`;
  if (status) url = `${url}&checkStatus=${status}`;

  return axiosApi({
    url: url,
    method: 'get'
  });
};

const createShipment = (data, type) => {
  let createType = 'createShipment';
  if (type === 'palletRate' || type === 'packageRate') {
    createType = 'rateShipment';
  } else if (type === 'pallets') {
    createType = 'createPalletShipment';
  } else if (type === 'save') {
    createType = 'saveShipmentRequest';
  }
  return axiosApi({
    method: 'post',
    url: `/shipments/${createType}`,
    data
  });
};
const getPackageShipmentRate = request => {
  return axiosApi({
    method: 'post',
    url: `/shipments/ratePackageShipment`,
    request
  });
};
const updateShipmentById = data => {
  return axiosApi({
    method: 'put',
    url: `/shipments/shipment`,
    data: { data }
  });
};

const deleteShipmentById = params => {
  return axiosApi({
    method: 'delete',
    url: `/shipments/shipment?shipment_id=${params.shipment_id}`
  });
};

const sendVerifyEmailLink = email => {
  return axiosApi({
    method: 'get',
    url: `/users/requestVerifyingMail?email=${email}`
  });
};

const getPalletInputOptions = () => {
  return axiosApi({
    method: 'get',
    url: `/shipments/getPalletShipmentOptions`
  });
};

const getCarrierList = () => {
  return axiosApi({
    method: 'get',
    url: `/companies/carrier-list`
  });
};
const voidShipment = row => {
  return axiosApi({
    method: 'post',
    url: `/shipments/voidShipment`,
    data: row
  });
};
const voidPackage = (row, packageId) => {
  return axiosApi({
    method: 'post',
    url: `/shipments/voidPackage`,
    data: { shipment: row, packageId }
  });
};

const getCognitoUserDataById = id => {
  return axiosApi({
    method: 'get',
    url: `/users/getUser?id=${id}`
  });
};

const getDeliveyProofUrl = async imageName => {
  return axiosApi({
    method: 'get',
    url: `/shipments/getShipmentPOD?photoPath=${imageName}`
  });
};

const fetchShipmentList = async params => {
  return await axiosApi({
    method: 'post',
    url: `/shipments/shipments`,
    data: params
  });
};

const fetchChipSummary = async params => {
  return await axiosApi({
    method: 'post',
    url: `/shipments/shipmentsSummary`,
    data: params
  });
};

const fetchShipmentbySearchid = async params => {
  return await axiosApi({
    method: 'post',
    url: `/shipments/searchShipments`,
    data: params
  });
};

const getUserPoolGroupsList = userPoolName => {
  return axiosApi({
    method: 'get',
    url: `users/getGroups?userPoolName=${userPoolName}`
  });
};
const updateUserGroup = data => {
  return axiosApi({
    method: 'post',
    url: `/users/updateUserGroup`,
    data: data
  });
};
const assignUserToGroup = data => {
  return axiosApi({
    method: 'post',
    url: `/users/assignUserToGroup`,
    data: data
  });
};

export {
  createShipment,
  getShipmentById,
  updateShipmentById,
  deleteShipmentById,
  sendVerifyEmailLink,
  exportShipmentCSV,
  voidShipment,
  voidPackage,
  getCognitoUserDataById,
  getDeliveyProofUrl,
  getCarrierList,
  getUserPoolGroupsList,
  updateUserGroup,
  assignUserToGroup,
  fetchShipmentList,
  getPalletInputOptions,
  fetchShipmentbySearchid,
  getPackageShipmentRate,
  fetchChipSummary
};
