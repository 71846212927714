// action - state management
import { SET_COMPANY_LIST, SET_WAREHOUSE_LIST } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //
const initialState = {
  companyList: [],
  warehouseList: []
};

const companyWareHouseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_LIST: {
      return {
        ...state,
        companyList: action.payload?.sort((a, b) =>
          a.company_name < b.company_name ? -1 : Number(a.company_name > b.company_name)
        )
      };
    }
    case SET_WAREHOUSE_LIST: {
      return {
        ...state,
        warehouseList: action.payload
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default companyWareHouseListReducer;
