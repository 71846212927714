// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import MenuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const userPermissions = useSelector(state => state?.user?.userData?.userPermissions);
  // if below variable is true then user can't see sidebar tabs except CONTACT_US
  // Because user did not completed company profil

  const navItems = MenuItem(userPermissions)?.items?.map(item => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
