// action - state management
import { SET_FILTER, SET_REPORT_SHIPMENTS, REPORT_UPDATED, CONFIRM_NAVIGATION } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
  filter: {},
  shipments: [],
  reportUpdated: false,
  confirmNavigate: true
};

const shipmentReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER: {
      return {
        ...state,
        filter: action.filter
      };
    }
    case SET_REPORT_SHIPMENTS: {
      return {
        ...state,
        shipments: action.shipments
      };
    }
    case REPORT_UPDATED: {
      return {
        ...state,
        reportUpdated: action.reportUpdated
      };
    }
    case CONFIRM_NAVIGATION: {
      return {
        ...state,
        confirmNavigate: action.confirmNavigate
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default shipmentReportReducer;
