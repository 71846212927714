import { getCompanyByName } from 'containers/Company/action';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { showErrorToaster } from 'ui-component/alertService';
import Loader from 'ui-component/Loader';
import {
  checkCompanyDetails,
  getUserRoleBasedPermission,
  isAuthenticatedUserShipxUser,
  isUserPartOfaGroup
} from 'utils';
import { getSession, logout } from 'views/pages/authentication/auth-forms/Accounts';
import * as actionTypes from '../store/actions';
const PrivateRoute = ({ component: Component, redirectTo, allowedRoles }) => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAccessRole, setIsAccessRole] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      getSession()
        .then(async res => {
          const payload = res.idToken.payload;
          const userGroups = res.idToken.payload['cognito:groups'] || [];
          let isCompanyHasAllDetails = false;
          let company_details = {};
          localStorage.setItem('user', JSON.stringify(payload));
          dispatch({ type: actionTypes.LOGIN, payload });

          if (!isUserPartOfaGroup(userGroups, history)) {
            return;
          }
          const isShipXUser = isAuthenticatedUserShipxUser(payload);
          // below here chacking the user has created a company details or not
          const company_name = payload['custom:company'];
          payload.companyDetails = {};
          const companyDetails = await getCompanyByName(company_name);
          const { data } = companyDetails;
          if (!data.length) {
            await logout(history);
            showErrorToaster('', 'Something is wrong. Please contact the admin');
            return;
          }

          company_details = data[0];
          payload.companyDetails = company_details;
          isCompanyHasAllDetails = checkCompanyDetails(company_details);
          payload.addUserCompanyDetails = !isCompanyHasAllDetails;
          //
          const permissions = getUserRoleBasedPermission(userGroups, isShipXUser);

          dispatch({ type: actionTypes.LOGIN, payload });
          dispatch({ type: actionTypes.SET_PERMISSIONS, payload: permissions });
          // This is matching the permissions for specific route coming from mainRoute
          // file (allowedRoles is mentioned in mainRoute file) and comparing with user permission
          if (permissions.role && allowedRoles?.includes(permissions.role)) {
            setIsAccessRole(true);
          }
          setIsUserAuthenticated(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    })();
  }, []);

  if (loading) {
    return <Loader />;
  }
  if (!isUserAuthenticated && !loading) {
    return <Navigate to={redirectTo} />;
  } else if (isAccessRole && isUserAuthenticated && !loading) {
    return <Component />;
  } else if (!isAccessRole && isUserAuthenticated && !loading) {
    return <Navigate to={redirectTo} />;
  }
};

export default PrivateRoute;
