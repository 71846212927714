import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import PrivateRoute from './PrivateRoute';
import config from 'config.js';

const PagesLanding = Loadable(
  lazy(() => import('views/pages/authentication/authentication/Login'))
);

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { userRoleKey } = config;
  const allowedRoles = {
    shipment: [
      userRoleKey.OPERATIONS_ADMIN,
      userRoleKey.ASSOCIATE,
      userRoleKey.CUSTOMER,
      userRoleKey.SHIPX
    ],
    operations: [userRoleKey.OPERATIONS_ADMIN, userRoleKey.ASSOCIATE, userRoleKey.SHIPX],
    // Below Here ADMIN user is added for non shipx company user who have ADMIN role so,
    // that they can access company routes for fill up the company information
    // TEMPORARY CONFIGURATION : We have added CUSTOMER_ASSOCIATE and CUSTOMER_ADMIN
    admin: [
      userRoleKey.SHIPX,
      userRoleKey.OPERATIONS_ADMIN,
      userRoleKey.CUSTOMER_ASSOCIATE,
      userRoleKey.CUSTOMER_ADMIN
    ],
    shipper: [
      userRoleKey.OPERATIONS_ADMIN,
      userRoleKey.ASSOCIATE,
      userRoleKey.CUSTOMER,
      userRoleKey.SHIPX,
      userRoleKey.CUSTOMER_ASSOCIATE,
      userRoleKey.CUSTOMER_ADMIN
    ]
  };
  return useRoutes([
    {
      path: '/',
      element: <PrivateRoute path="/" component={PagesLanding} redirectTo="/login" />
    },
    AuthenticationRoutes(allowedRoles),
    MainRoutes(allowedRoles)
  ]);
}
