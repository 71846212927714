// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBasket, IconNetwork } from '@tabler/icons';
import { Typography } from '@mui/material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { ApartmentIcon, PeopleAltIcon } from 'assets/images/icons';

// constant
import HubIcon from '@mui/icons-material/Hub';
const icons = {
  IconBasket,
  ApartmentRoundedIcon,
  PeopleAltOutlinedIcon,
  HubIcon,
  ApartmentIcon,
  PeopleAltIcon,
  IconNetwork
};
// ==============================|| APPLICATION MENU ITEMS ||============================== //

const sideBarAdmin = {
  id: 'admin-application',
  title: <FormattedMessage id="Admin" />,
  type: 'group',
  children: [
    {
      id: 'companies',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="companies" />
        </Typography>
      ),
      type: 'item',
      url: '/customer/company-list',
      icon: icons.ApartmentIcon,
      breadcrumbs: false
    },
    {
      id: 'manageusers',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="Manage Users" />
        </Typography>
      ),
      type: 'item',
      url: '/dashboard/userManager',
      icon: icons.PeopleAltIcon,
      breadcrumbs: false
    },
    {
      id: 'network',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="network" />
        </Typography>
      ),
      type: 'item',
      url: '/customer/network',
      icon: icons.IconNetwork,
      breadcrumbs: false
    }
    // {
    //   id: 'warehouses',
    //   title: (
    //     <Typography variant="subtitle1">
    //       <FormattedMessage id="warehouses" />
    //     </Typography>
    //   ),
    //   type: 'item',
    //   url: '/customer/warehouse-list',
    //   icon: icons.HomeIcon,
    //   breadcrumbs: false,
    // },
  ]
};
export default sideBarAdmin;
