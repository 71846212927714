// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBasket } from '@tabler/icons';
import { Typography } from '@mui/material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
// constant
const icons = {
  IconBasket,
  ApartmentRoundedIcon,
  PeopleAltOutlinedIcon
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const sideBarAdminWithCompany = {
  id: 'admin-application',
  title: <FormattedMessage id="Admin" />,
  type: 'group',
  children: [
    {
      id: 'company',
      title: (
        <Typography variant="subtitle1">
          <FormattedMessage id="Company" />
        </Typography>
      ),
      type: 'item',
      url: '/customer/company',
      icon: icons.ApartmentRoundedIcon,
      breadcrumbs: false
    }
  ]
};
export default sideBarAdminWithCompany;
